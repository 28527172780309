import React, { useEffect, useState } from 'react';
import Menubar from '../components/Menubar';
import Footer from '../components/Footer';
import DevsecopsContactForm from '../components/DevsecopsContactForm';
import PrimaryNaicsCodes from '../components/PrimaryNaicsCodes';
import SecondaryNaicsCodes from '../components/SecondaryNaicsCodes';
import { Link } from 'react-router-dom';
import usePageTracking from '../components/PageTracking';

function ContractVehicles() {
  // eslint-disable-next-line max-len
  const [pageTitle, setPageTitle] = useState('Contract Vehicles | GSA | DIR | USDA | MDFA | Kansas Department of Administration`');
  useEffect(() => {
    document.title = pageTitle; // Set the page title to the current state value
  }, [pageTitle]);

  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const PDF1 = './assets/pdf/DIR-CPO-4561_contract.pdf';
  const PDF3 = './assets/pdf/DIR-CPO-4561%20Appendix%20A_Standard'+
  '%20Contract%20Terms%20and%20Conditions.pdf';
  const PDF4 = './assets/pdf/DIR-CPO-4561%20'+
  'Appendix%20B%20HUB%20Subcontracting%20Plan.pdf';
  const PDF5 ='./assets/pdf/DIR-CPO-4561%20'+
  'Appendix%20C_ITSAC%20NotToExceedRates.pdf';
  const PDF6='./assets/pdf/DIR-CPO-4561%20'+
  'Appendix%20D_ITSAC%20Titles%20and%20Descriptions.pdf';
  const PDF7='./assets/pdf/DIR-CPO-TMP-445_RFO%20ITSAC%20Services.pdf';

  return (
    <>
      <Menubar />
      {/* Hero */}
      <div className='py-lg-20 pt-14 pb-10 bg-cover ContractVehiclesHeroImage'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
              <div className='text-white'>
                <h1 className='text-white mb-3 display-4 whiteText-responsive'>
                  CONTRACT VEHICLES
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Nav Buttons */}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <Link to='/ContractVehicles'
              className='btn btn-primary-training pt-3'
              data-testid='primary button'>
              <span className='h3'>
                  CONTRACT VEHICLES
              </span>
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 pl-0 pr-0'>
            <a href='#NaicsCodes'
              className='btn btn-secondary-training pt-3'
              data-testid='secondary button'>
              <span className='h3'>
                  NAICS CODES
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className='p-5 whiteContainer'>
        <div data-testid='contracts text 1'>
          <div className='pl-5 pr-5 pb-3 contractVpara text-center'>
            <span className='h2 fw-bold'>OUR CONTRACT PORTFOLIO <br/></span>
            includes GSA IT Schedules,
            Agency Specific IDIQs, and <br/>
            Blanket Purchase Agreements (BPAs).<br/><br/>
            Learn more about our technical and professional serivces under
            pre-negotiated terms and conditions below.
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className="row row-cols-3 row-cols-md-3 pt-3">
          <div className="col mb-4">
            <div className="card h-100">
              <div className='card-header'>
                <img src='./assets/DIR.png' alt='TDIR'
                  className='img-fluid'
                  width={223}
                  height={110}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">DIR-CPO-5197</div>
                <div className="card-text h5">
                Comprehensive Web Development and Managed Services
                </div>
              </div>
              <div className="card-footer">
                <Link to='/dir-cpo-5197' className='btn btn-secondary'>
                    VIEW CONTRACT DETAILS
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img src='./assets/gsa.svg'
                  alt='GSA'
                  className='img-fluid'
                  width={112}
                  height={112}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">BEST-IN-CLASS STARS III
                  <br />47QTCB22D0170</div>
                <div className="card-text h5">
                GSA 8(A) STARS III Government-Wide
                Acquisition Contract</div>
              </div>
              <div className="card-footer">
                <Link to='/stars3' className='btn btn-secondary'>
                    VIEW CONTRACT DETAILS
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img src='./assets/DIR.png' alt='TDIR'
                  className='img-fluid'
                  width={223}
                  height={110}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">DIR-CPO-4561</div>
                <p className="card-text h5">Information
              Technology Staff Augmentation Contracts
              (ITSAC) For Personnel To Support Customer IT Projects</p>
                <p>
                  <a href={PDF1}
                    target={self}
                    className='contractVehicleLinks'>
                    - Appddiction Studio DIR-CPO-4561 Contract
                  </a>
                </p>
                <p>
                  <a
                    href='https://dir.texas.gov/View-About-DIR/Pages/Content.aspx?id=41'
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - Appddiction Studio DIR Cooperative Contracts
                  </a>
                </p>
                <p>
                  <a href={PDF3}
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - DIR Standard Terms and Conditions
                  </a>
                </p>
                <p>
                  <a href={PDF4}
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - Appddiction Studio DIR Appendix B HUB Subcontracting Plan
                  </a>
                </p>
                <p>
                  <a
                    href={PDF5}
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - Appddiction Studio DIR Appendix C ITSAC NotToExceedRates
                  </a>
                </p>
                <p>
                  <a href={PDF6}
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - Appddiction Studio DIR Appendix D ITSAC Titles and
                    Descriptions
                  </a>
                </p>
                <p>
                  <a
                    href={PDF7}
                    target={self}
                    className='contractVehicleLinks'
                  >
                    - Appddiction Studio DIR RFO ITSAC Services
                  </a>
                </p>
              </div>
              <div className="card-footer">
                <Link to='/dirdetails' className='btn btn-secondary'>
                  VIEW CONTRACT DETAILS
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img
                  src='./assets/gsa.svg'
                  alt='General Services Administration'
                  className='img-fluid'
                  width={112}
                  height={112}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">GS-35F0028GA:</div>
                <p className="card-text">General Services
                Administration (GSA) MAS IT Category</p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><p>&nbsp;</p></small>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 pt-3">
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img src='./assets/usda.svg' alt='GSA'
                  className='img'
                  width={160}
                  height={110}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">
                  United States Department of Agriculture (USDA)</div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img src='./assets/DFA.png' alt='DFA'
                  className='img-fluid'
                  width={120}
                  height={120}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">
                  Mississippi Department Of Finance And Administration,
                  Office Of Personal Service Contract Review</div>
                <div className='h5'>
              - Professional Nursing Services Pre-approved Vendor List
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className='card-header'>
                <img
                  src='./assets/KDOA.png'
                  alt=''
                  className='img-fluid'
                  width={223}
                  height={121}
                />
              </div>
              <div className="card-body">
                <div className="card-title h4">
                  Kansas Department of Administration
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='whiteContainer mt-5'>
        {/* row of icons */}
        <div className='container'>
          <div className='row' id='icons'>
            <div className='col-6 text-center'>
              <img
                src='./assets/Duns.png'
                className='contractIcons pb-3'
                width={100}
                height={116}
                alt='DUNS 968907076'
              />
              <div className='h4'>DUNS 968907076</div>
            </div>
            <div className='col-6 text-center'>
              <img
                src='./assets/Cage.png'
                className='contractIcons pb-3'
                alt='CAGE 6HR48'
                width={100}
                height={116}
              />
              <div className='h4'>CAGE 6HR48</div>
            </div>
            {/* <div className='col'>
              <img
                src='./assets/security.png'
                className='contractIcons pb-3'
                alt='secret clearance'
              />
              <h4>Secret Security Clearance</h4>
            </div> */}
            {/* <div className='col-sm'>
              <img
                src='./assets/TopSecret.png'
                className='contractIcons pb-3'
                alt='top secret pending'
              />
              <h4>Top Secret Clearance Pending</h4>
            </div> */}
          </div>
        </div>
        <div id='NaicsCodes'></div>
      </div>

      {/* NAICS CODE */}
      <h1
        className='text-dark text-center mb-3 display-4 whiteText-responsive
       pt-5'
      >
        NAICS Codes
      </h1>
      {/* NAICS Codes Tabs */}
      <div>
        <nav className='d-flex justify-content-center'>
          <div className='nav nav-tabs' id='nav-tab' role='tablist'>
            <a
              className='nav-item nav-link active text-center pt-3'
              id='PrimaryNaicsCodesTab'
              data-toggle='tab'
              href='#nav-home'
              role='tab'
              aria-controls='nav-home'
              aria-selected='true'
            >
              Primary NAICS Codes
            </a>
            <a
              className='nav-item nav-link text-center pt-3'
              id='SecondaryNaicsCodesTab'
              data-toggle='tab'
              href='#nav-profile'
              role='tab'
              aria-controls='nav-profile'
              aria-selected='false'
            >
              Secondary NAICS Codes
            </a>
          </div>
        </nav>
        <div className='tab-content' id='nav-tabContent'>
          <div
            className='tab-pane fade show active'
            id='nav-home'
            role='tabpanel'
            aria-labelledby='nav-home-tab'
          >
            <PrimaryNaicsCodes />
          </div>
          <div
            className='tab-pane fade'
            id='nav-profile'
            role='tabpanel'
            aria-labelledby='nav-profile-tab'
          >
            <SecondaryNaicsCodes />
          </div>
        </div>
      </div>
      {/* download capabilities */}
      <div className='container fluid pt-5 pb-5'>
        <div className='row'>
          <div className='col-sm text-center' id='icons'>
            <img src='./assets/downloadIcon.svg'
              className='img-fluid downloadIcon'
              alt=''
              width={200}
              height={200}
            />
          </div>
          <div className='col-sm-8 pt-2'>
            <p className='leadGrey'>
              Our collection of capabilities defines
              the work we do daily to help customers achieve
              the crucial tasks in their industry. Appddiction
              Studio provides value and transparency to deliver
              successful services. Our capabilities are laid out
              to showcase our past and current performances, where we
              have empowered organization with a next level transformation.
            </p>
            <button
              type='button'
              className='btn btn-secondary'
            >
              DOWNLOAD CAPABILITIES
            </button>
          </div>
        </div>
      </div>
      <DevsecopsContactForm />
      <Footer />
    </>
  );
}
export default ContractVehicles;
