/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import usePageTracking from './PageTracking';
import ReactGA4 from 'react-ga4';

const Upcomingcourses = () => {
  const [courses, setCourses] = useState([]);
  const getCourseList = async () => {
    try {
      const response = await fetch(
        `${window.__ENV__.API_URL}/courses/api/get/`
        // 'http://localhost:8080/courses/api/get/'
      );
      const jsonData = await response.json();
      return jsonData;
    } catch (err) {
      console.error(err.message);
    }
  };

  usePageTracking();

  useEffect(() => {
    const fetchCourseList = async () => {
      const courseList = await getCourseList();
      setCourses(courseList);
    };

    fetchCourseList();
  }, []);

  // The following calculates the number of days a course will last
  const durationDays = duration => {
    if (duration) {
      const matches1 = /(\w+)\s+(\d+)-(\d+),\s+(\d+)/;
      const matches2 = /(\w+)\s+(\d+)\s*-\s*(\w+)\s*(\d+)(?:,\s*(\d+))?/;

      let startDate;
      let endDate;

      if (matches1.test(duration)) {
        const [, startMonth, startDay, endDay, year] = duration.match(matches1);
        startDate = new Date(`${startMonth} ${startDay}, ${year}`);
        endDate = new Date(`${startMonth} ${endDay}, ${year}`);
      } else if (matches2.test(duration)) {
        const [, startMonth, startDay, endMonth, endDay, year] =
          duration.match(matches2);
        startDate = new Date(`${startMonth} ${startDay}, ${year}`);
        endDate = new Date(`${endMonth} ${endDay}, ${year}`);
      } else {
        console.log('Invalid duration string format');
        return;
      }
      const timeDiff = endDate.getTime() - startDate.getTime();
      const durationDays = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
      return durationDays;
    } else {
      console.log('Duration string is undefined or null');
    }
  };

  return (
    <>
      <div>
        <div className="p-2">
          <h1 className="text-center pb-1 pt-4">Upcoming Courses</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {courses.map(course => (
            <div className="col-md-4 col-sm-8 col-xs-12 pb-4" key={course.id}>
              <div className="card">
                <div className="pt-2 pl-3 pb-4 pt-4 text-center">
                  <span className="badge rounded-pill badge-warning py-1 px-2">
                    {durationDays(course.durationString)} Day Class
                  </span>
                  <span className="badge rounded-pill badge-secondary py-1 px-2">
                    {course.inPerson ? 'In Person' : 'Zoom™ Class'}
                  </span>
                </div>
                <img
                  src={
                    course.courseBadge !== null ?
                      course.courseBadge :
                      './assets/Appddlogo.svg'
                  }
                  className="courseBadges"
                  alt={course.courseTitle}
                  width={300}
                  height={200}
                />
                <div className="card-body">
                  <div className="h4 font-weight-bold text-center">
                    {(course?.courseTitle?.length > 30 ? `${course.courseTitle.slice(0, 30)}...` : course?.courseTitle) || ''
                    }
                  </div>

                  <div className="h5 text-center">{course.durationString}</div>
                  <div className="h5 pb-3 text-center">
                    {course.startTime}-{course.endTime} CST
                  </div>
                  <div className="text-center">
                    {courses.inPerson ? (
                      <img
                        src={course.locationBadge}
                        className="courseBrands"
                        alt={course.courseTitle}
                      />
                    ) : (
                      <img
                        src={course.locationBadge}
                        className="courseBrands"
                        alt="Zoom Class"
                        width={80}
                        height={27}
                      />
                    )}
                    {course.inPerson ? 'In Person' : ' Class'}
                  </div>
                  <p className="h2 text-center">${course.total}.00</p>
                </div>
                <div className="text-center font-weight-bold pb-3">
                  <Link to={'/order' + course.id}>
                    <button
                      aria-label={`${course?.courseTitle ?? ''} ${course?.durationString ?? ''}`}
                      className="btn btn-blue font-weight-bold w-75"
                      onClick={() => {
                        ReactGA4.event({
                          category: 'Course Details',
                          action: 'Button Click',
                          label: course?.courseTitle ?? '',
                          value: course?.id ?? ''
                        });
                      }}
                    >
                      ENROLL NOW <i className="bi bi-chevron-double-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="accordion" id={`accordion${course.id}`}>
                  <div className="card">
                    <div className="card-header" id={`heading${course.id}`}>
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapse${course.id}`}
                          aria-expanded="true"
                          aria-controls={`collapse${course.id}`}
                          aria-label={`Details about the ${course.courseTitle} 
                           course on ${course.durationString}`}
                        >
                          COURSE DETAILS
                          <i className="bi bi-chevron-double-right"></i>
                        </button>
                      </h2>
                    </div>

                    <div
                      id={`collapse${course.id}`}
                      className="collapse"
                      aria-labelledby={`heading${course.id}`}
                      data-parent={`#accordion${course.id}`}
                    >
                      <div
                        className="card-body"
                        dangerouslySetInnerHTML={{
                          __html: `${course.stripePrice} <br><br>
                          <strong>Instructor:</strong> ${course.instructorOne} <br>
                          ${course.instructorTwo ? course.instructorTwo : ''}
                          `
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Upcomingcourses;
